import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { LuAlertTriangle, LuEye, LuEyeOff } from "react-icons/lu";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import {
  loginUser,
  verifyOTPForUserWithToken,
} from "../redux/features/auth/authSlice";
import { toast } from "react-toastify";
import Loading from "../components/ui/Loading";
import { useAdminLoginMutation } from "../redux/features/user/userApi";
import { IGenericErrorMessage, ResponseSuccessType } from "../types/common";
import VerificationInput from "react-verification-input";

type Inputs = {
  name: string;
  email: string;
  password: string;
};

const SignIn = () => {
  const { isLoading } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(false);
  const [emailPassword, setEmailPassword] = useState({
    email: "",
    password: "",
  });
  const [countdown, setCountdown] = useState(30);
  const [token, setToken] = useState("");

  const [isDisabled, setIsDisabled] = useState(true);

  const [adminLogin, { isLoading: adminLoading }] = useAdminLoginMutation();

  const router = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    // dispatch(loginUser(data));
    await adminLogin(data)
      .unwrap()
      .then((res: ResponseSuccessType) => {
        setEmailPassword({
          email: data.email,
          password: data.password,
        });
        toast.success(res.message || "Send OTP Successful");
      })
      .catch((res) => {
        toast.error(res.message || "Something went wrong");
      });
  };

  const handleVerify = async () => {
    if (token.length !== 4) {
      toast.error("invalid token");
      return;
    }
    dispatch(
      verifyOTPForUserWithToken({
        email: emailPassword.email,
        password: emailPassword.password,
        otp: parseInt(token),
      })
    )
      .unwrap()
      .then((res: any) => {
        toast.success(res.message || "Successfully verification complete");
        dispatch(loginUser(emailPassword));
        router("/");
      })
      .catch((err: IGenericErrorMessage) => {
        toast.error(err.message || "something went wrong to send email");
      });
  };

  const handleResend = async () => {
    await adminLogin(emailPassword)
      .unwrap()
      .then((res) => {
        toast.success("Successfully verification email sent");
      })
      .catch((err) => {
        toast.error(err.message || "something went wrong to send email");
      });
    setIsDisabled(true);
  };

  useEffect(() => {
    let countdownInterval: NodeJS.Timeout;

    if (isDisabled) {
      // Start countdown when button is disabled
      countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 1) {
            // If countdown reaches 1, enable the button
            setIsDisabled(false);
            clearInterval(countdownInterval);
            return 30; // Reset countdown to 30 seconds
          }
          return prevCountdown - 1;
        });
      }, 1000); // Update countdown every second
    }

    // Clear interval when component is unmounted or when button is re-enabled
    return () => clearInterval(countdownInterval);
  }, [isDisabled]);

  if (isLoading) {
    return (
      <div>
        <Loading></Loading>
      </div>
    );
  }
  return (
    <div className="flex h-[100vh]">
      <img
        className="hidden h-full lg:block w-2/5"
        src="/images/sign-up.png"
        alt="Sign up"
      />
      <div className="w-full h-fit lg:w-3/5 px-4 lg:px-20 2xl:px-24 py-12 lg:py-24 2xl:py-32 2xl:pb-0">
        {!emailPassword.email && !emailPassword.password ? (
          <>
            <h2 className="heading text-primary">Sign In</h2>
            <p className="pt-1 lg:pt-4 2xl:pt-5 text-sm lg:text-base text-[#5C5D5E]">
              Sign in with your valid account information
            </p>

            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col gap-2 2xl:gap-3 pt-4 lg:pt-6 2xl:pt-8  max-w-[435px]"
            >
              <div className="flex flex-col gap-1">
                <label htmlFor="email" className="cursor-pointer">
                  Email
                </label>
                <input
                  id="email"
                  className="input"
                  placeholder="e.g. demo@gmail.com"
                  {...register("email", { required: true })}
                />
                {errors.email && (
                  <div className="px-2 py-1 lg:py-1.5 flex gap-1 lg:gap-2 rounded border bg-[#FFF2E2] border-[#F87034] ">
                    <LuAlertTriangle className="text-[#F87034]" />
                    <p className="text-primary text-[10px] leading-[12px] lg:leading-4 lg:text-xs">
                      The email and password you entered doesn’t match our
                      records. Please double-check and try again.
                    </p>
                  </div>
                )}
              </div>

              <div className=" flex flex-col gap-1">
                <label htmlFor="password" className="cursor-pointer">
                  Password
                </label>
                <div className="relative w-full">
                  <input
                    type={show ? "text" : "password"}
                    id="password"
                    className="input w-full"
                    placeholder="e.g.***********"
                    {...register("password", { required: true })}
                  />
                  {show ? (
                    <LuEyeOff
                      onClick={() => setShow((prev) => !prev)}
                      className="absolute right-3 top-[30%] cursor-pointer text-[#BABABA] text-2xl"
                    />
                  ) : (
                    <LuEye
                      onClick={() => setShow((prev) => !prev)}
                      className="absolute right-3 top-[30%] cursor-pointer text-[#BABABA] text-2xl"
                    />
                  )}
                </div>
                {errors.email && (
                  <div className="px-2 py-1 lg:py-1.5 flex gap-1 lg:gap-2 rounded border bg-[#FFF2E2] border-[#F87034] ">
                    <LuAlertTriangle className="text-[#F87034]" />
                    <p className="text-primary text-[10px] leading-[12px] lg:leading-4 lg:text-xs">
                      The email and password you entered doesn’t match our
                      records. Please double-check and try again.
                    </p>
                  </div>
                )}
              </div>

              <div className="flex items-center justify-between">
                <div className="flex gap-2">
                  <input type="checkbox" id="policy" className="" />
                  <label
                    htmlFor="policy"
                    className="text-sm cursor-pointer font-medium text-[#75777a]"
                  >
                    Remember me
                  </label>
                </div>
                <Link
                  to={"/forgot-password"}
                  className="text-primary underline text-sm lg:text-base"
                >
                  Forgot Password
                </Link>
              </div>
              <input
                disabled={adminLoading}
                className="secondaryBtn mt-4 lg:mt-6 2xl:mt-10  max-w-[435px]"
                value={"Sign In"}
                type="submit"
              />
            </form>

            <div className="text-center pt-4 lg:pt-6 2xl:pt-10 font-medium text-textSecondary  max-w-[435px]">
              Don’t have an account?{" "}
              <Link to={"/sign-up"} className="text-primary font-semibold">
                {" "}
                Sign up
              </Link>
            </div>
          </>
        ) : (
          <>
            <h2 className="heading text-primary pt-4 lg:pt-8 2xl:pt-12">
              Verification Code
            </h2>
            <p className="pt-2 lg:pt-4 2xl:pt-5 text-sm lg:text-base text-[#5C5D5E]">
              We’ve sent the code to your mail address that you include:{" "}
            </p>
            <h3 className="text-lg font-semibold">{emailPassword.email}</h3>
            <div className="flex flex-col gap-3 pt-2 lg:pt-8 2xl:pt-4 max-w-[435px]">
              <div className="flex justify-center mb-5">
                <VerificationInput
                  onChange={(e) => {
                    setToken(e);
                  }}
                  validChars="0-9"
                  // containerProps={{ className: "gap-2" }}

                  classNames={{
                    character:
                      "border  text-center border-[#E7CF62]  rounded-xl size-14 2xl:size-14 bg-white",
                    characterInactive:
                      "border text-center border-[#E7CF62] rounded-xl size-14 2xl:size-14",
                    characterFilled:
                      "border text-center border-[#E7CF62] rounded-xl size-14 2xl:size-14",
                    characterSelected:
                      "border text-center border-[#E7CF62] rounded-xl size-14 2xl:size-14",
                    //   container:
                    // "border text-center border-[#E7CF62] rounded-xl size-14 2xl:size-14",
                  }}
                  // placeholder=""
                  length={4}
                />
              </div>
              <div className="flex items-center justify-end">
                <button
                  disabled={isDisabled}
                  onClick={handleResend}
                  className="text-[#82491E] underline text-sm lg:text-base"
                >
                  Resend Code {isDisabled && <span> in {countdown}s</span>}
                </button>
              </div>
              <button
                onClick={handleVerify}
                disabled={token.length < 4}
                className="secondaryBtn mt-4 disabled:opacity-40 disabled:cursor-not-allowed"
                value={"Sign In"}
              >
                Verify
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SignIn;
