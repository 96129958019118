import React from 'react';

const TermsAndConditions: React.FC = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 lg:px-12 py-8">
      <h1 className="text-3xl font-bold mb-6 text-center">TERMS AND CONDITIONS</h1>
      <hr />

      <div className="space-y-8 pt-8">
        <section>
          <h2 className="text-[20px] font-semibold mb-4">
            1. AGREEMENT BETWEEN PROMISELAND INNOVATIONS LIMITED AND THE USER
          </h2>
          <p className="text-base leading-7">
            1.1 In this document:
            <br />
            (a) "We", "us", or "Wealth App" means Promise Land Innovations
            Limited (“PLIL”).
            <br />
            (b) “Wealth App” or the “Mobile Application” or “Mobile App” or
            “App” means the software Application provided by PLIL to offer
            services related to real estate services to be used on Android and
            Apple iOS and Android OS devices and any upgrades from time to time
            and any other software or documentation which enables the use of the
            App. The App also means{' '}
            <a
              href="https://wealthapp.live"
              className="text-blue-600 underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://wealthapp.live
            </a>{' '}
            and related websites, software, and other related services provided
            by us, or any third party designated by us on or through the App,
            excluding any services provided under a separate written agreement;
            and
            <br />
            (c) "User” or “you” means a user of the Mobile App.
            <br />
            1.2 This Agreement governs your use of the Mobile App. Other terms
            may apply to your use of specific features or parts of our App.
          </p>
        </section>

        <section>
          <h2 className="text-[20px] font-semibold mb-4">
            2. GENERAL TERMS AND CONDITIONS
          </h2>
          <p className="text-base leading-7">
            2.1 By installing the Mobile App, you agree to be bound by these
            Terms of Use (“Agreement”). Please review them carefully before
            installation and/or acceptance. BY ACCEPTING THIS AGREEMENT BY
            CLICKING A BOX INDICATING YOUR ACCEPTANCE YOU AGREE TO THE TERMS OF
            THIS AGREEMENT. IF YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF
            A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE
            AUTHORITY TO BIND SUCH ENTITY AND ITS AFFILIATES TO THESE TERMS AND
            CONDITIONS, IN WHICH CASE THE TERMS “YOU” OR “YOUR” SHALL REFER TO
            SUCH ENTITY AND ITS AFFILIATES. IF YOU DO NOT HAVE SUCH AUTHORITY,
            OR IF YOU DO NOT AGREE WITH THESE TERMS AND CONDITIONS, YOU MUST NOT
            ACCEPT THIS AGREEMENT AND MAY NOT USE THE SERVICES.
            <br />
            <br />
            2.2 Your use of the App is subject to the terms set out below and
            any other terms and policies made applicable to the User, including
            but not limited to PLIL’s Privacy Policy.
            <br />
            <br />
            2.3 If any provision of this Agreement is held to be invalid by any
            judicial or administrative authority, then that provision will be
            removed from this Agreement without affecting the remaining
            provisions of this Agreement, which will continue to be valid and
            enforceable.
            <br />
            <br />
            2.4 The User acknowledges and agrees that each PLIL Affiliate shall
            be a third-party beneficiary to this Agreement and shall be entitled
            to directly enforce, and rely upon, any provision of this Agreement
            which confers a benefit on (or rights in favor of) them.
            <br />
            <br />
            2.5 PLIL will not be liable or responsible for any failure to
            perform, or delay in performance of, any of the obligations under
            this Agreement that is caused by acts or events beyond PLIL’s
            reasonable control.
          </p>
        </section>

        <section>
          <h2 className="text-[20px] font-semibold mb-4">
            3. PROPRIETARY RIGHTS AND LICENSE
          </h2>
          <p className="text-base leading-7">
            3.1 PLIL grants to you a limited, royalty-free, non-exclusive,
            personal, and non-transferable license to install and use the App on
            a single personal mobile electronic device or smartphone owned or
            controlled by you solely for your own personal, commercial purposes,
            subject to your compliance with these Terms.
          </p>
        </section>

        <section>
          <h2 className="text-[20px] font-semibold mb-4">4. TERM</h2>
          <p className="text-base leading-7">
            4.1 This Agreement shall commence on the date you confirm your
            acceptance of the terms stated herein and shall continue unless
            terminated in accordance with the terms relating to termination.
          </p>
        </section>

        <section>
          <h2 className="text-[20px] font-semibold mb-4">
            5. CONTENT AND SERVICES
          </h2>
          <p className="text-base leading-7">
            5.1 Wealth App is a service for individuals to use to conduct real
            estate transactions, including but not limited to buying, selling,
            and flipping of properties. You may use it for personal and
            commercial purposes in accordance with all applicable federal,
            state, and local laws. Organizations, companies, and businesses may
            become registered members or use our Apps as entities or through
            individual members provided that it provides its Know-Your-Client
            (KYC) documents for our due diligence protocols.
            <br />
            <br />
            5.2 You agree to use the Mobile App and any Service responsibly, and
            in a manner, that is not unlawful, threatening, harassing, abusive,
            defamatory, slanderous, libelous, harmful to minors, vulgar,
            gratuitously violent, obscene, pornographic, indecent, lewd,
            invasive of another's privacy, or racially, ethnically, or otherwise
            offensive, hateful, or abusive. You also agree not to intentionally
            use any Service for inappropriate purposes.
            <br />
            <br />
            5.3 The content and services may not be used for any illegal purpose.
            You may not access our networks, computers, or the content and
            services in any manner that could damage, disable, overburden, or
            impair them, or interfere with any other person's use and enjoyment.
            You may not attempt to gain unauthorized access to any content or
            services, other accounts, computer systems, or networks connected
            with PLIL, the content, or services. You may not use any automated
            means to access the Wealth App, the content, or services for any
            purpose. Such unauthorized access includes, but is not limited to,
            using another person's login credentials to access Wealth App. Any
            attempt by any individual or entity to solicit login information of
            any other user or Wealth App personnel or to access any such account
            is an express and direct violation of these Terms of Use and of
            applicable law, including relevant privacy and security laws and
            laws prohibiting unfair or unethical practices.
            <br />
            <br />
            5.4 The platforms available via the Apps (the “Wealth App Platforms”)
            offer features and services available to you via your mobile phone
            or other mobile device. These features and services may include the
            ability to upload content to the Wealth App Platforms, receive
            messages from the Wealth App Platforms, download applications to
            your mobile phone, or access Wealth App Platforms features
            (collectively, the "Mobile Features"). Standard messaging, data, and
            other fees may be charged by your carrier. Your carrier may prohibit
            or restrict certain mobile features, and certain mobile features may
            be incompatible with your carrier or mobile device. Contact your
            carrier with questions regarding these issues. As applicable,
            instructions regarding how to opt-out of mobile features will be
            disclosed in connection with mobile features.
            <br />
            <br />
            5.5 You agree to notify Wealth App of any changes to your mobile
            number and update your account(s) on the Wealth App Platforms to
            reflect this change.
            <br />
            <br />
            5.6 You agree that information provided by you on the Wealth App
            Platforms shall be only specifically to transaction information to
            the exemption of any and all personal comments or such other
            comments which are unrelated to the transaction performed on the
            Wealth App Platforms.
            <br />
            <br />
            5.7 When you use the Mobile App, you agree we may send you
            notifications automatically via email, mobile telephone, or other
            contact information you provide us in accordance with the PLIL
            Privacy Policy.
          </p>
        </section>

        <section>
          <h2 className="text-[20px] font-semibold mb-4">
            6. DISCLAIMERS AND LIMITATIONS OF LIABILITY
          </h2>
          <p className="text-base leading-7">
            6.1 Wealth App and any service included on or otherwise made
            available to you through this platform is provided on an "as is"
            and "as available" basis. Neither Wealth App nor its suppliers make
            any representations or warranties of any kind, express or implied,
            as to the operation of Wealth App or the information, content,
            materials, or products included or otherwise made available to you
            through Wealth App. You expressly agree that your use of Wealth App
            is at your sole risk.
            <br />
            <br />
            6.2 To the full extent permissible by applicable law, Wealth App and
            its suppliers disclaim all warranties, express or implied, including,
            but not limited to, implied warranties of merchantability and
            fitness for a particular purpose. Wealth App does not warrant that
            Wealth App; information, content, materials, products (including
            software) or other services included on or otherwise made available
            to you through Wealth App; its servers; or e-mail sent from Wealth
            App are free of viruses or other harmful components. Wealth App will
            not be liable for any damages of any kind arising from the use of
            any Wealth App service, or from any information, content, materials,
            products (including software), or other services included on or
            otherwise made available to you through Wealth App, including, but
            not limited to direct, indirect, incidental, punitive, and
            consequential damages, unless otherwise specified in writing.
            <br />
            <br />
            6.3 Certain state laws do not allow limitations on implied warranties
            or the exclusion or limitation of certain damages. If these laws
            apply to you, some or all of the above disclaimers, exclusions, or
            limitations may not apply to you, and you might have additional
            rights.
            <br />
            <br />
            6.4 You agree to indemnify and hold Wealth App, its partners,
            suppliers, affiliates, and agents harmless from any claim, demand,
            loss, or damage, including reasonable attorneys’ fees, arising out
            of or related to your use of Wealth App, or your violation of these
            Terms of Use, your breach of any agreement, or the infringement by
            you, or any third party using your account, of any intellectual
            property or other right of any person or entity.
          </p>
        </section>

        <section>
          <h2 className="text-[20px] font-semibold mb-4">7. MODIFICATIONS</h2>
          <p className="text-base leading-7">
            7.1 Wealth App reserves the right to make changes to our site, these
            Terms of Use, and any policies from time to time. You should review
            our policies, terms, and conditions each time you visit the Wealth
            App site or use our services to ensure that you understand the terms
            and conditions that apply at that time. Your continued use of Wealth
            App and our services constitutes your agreement to these terms and
            conditions as amended.
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermsAndConditions;
